<template>
  <div>

    <b-row>
      <b-col
        v-if="index > 0"
        cols="12"
      >
        <hr>
      </b-col>
      <b-col
        md="2"
      >
        <b-form-group
          :label="$t('Documento')"
        >
          <div class="text-truncate txt-label d-none d-sm-block">
            <b-link
              :href="data.sw281s04_url"
              target="_blank"
              class="text-break-word"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-25"
              /> {{ data.sw281s04 }}
            </b-link>
          </div>

          <div class="text-truncate d-xs-block d-sm-none">
            <b-link
              :href="data.sw281s04_url"
              target="_blank"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-25"
              /> {{ data.sw281s04 }}
            </b-link>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          :label="$t('Comentário')"
        >
          <b-form-input
            v-if="view===false"
            v-model="data.sw281s05"
            type="text"
            autocomplete="off"
            @input="updateAction(data)"
          />
          <template v-else>
            <p class="txt-label text-truncate d-none d-sm-block text-primary mb-0">
              {{ data.sw281s05 || '-----' }}
            </p>
            <p class="text-truncate d-xs-block d-sm-none text-primary mb-0">
              {{ data.sw281s05 || '-----' }}
            </p>
          </template>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          :label="$t('Tipo de documento')"
        >
          <v-select
            v-if="view===false"
            v-model="data.sw281s08"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="data.tipos"
            append-to-body
            :calculate-position="withPopper"
            label="txt"
            item-text="txt"
            item-value="id"
            :placeholder="$t('Escolha um tipo')"
            @input="updateAction(data)"
            @change="updateAction(data)"
          >
            <template #option="{ txt }">
              {{ txt }}
            </template>
            <div slot="no-options">
              {{ $t('Sem resultados') }}
            </div>
          </v-select>
          <template v-else>
            <p class="txt-label text-truncate d-none d-sm-block text-primary mb-0">
              {{ data.sw281s08 | getTxt }}
            </p>
            <p class="text-truncate d-xs-block d-sm-none text-primary mb-0">
              {{ data.sw281s08 | getTxt }}
            </p>
          </template>
        </b-form-group>
      </b-col>
      <b-col
        md="2"
      >
        <b-form-group
          :label="$t('Inserido por')"
        >
          <template v-if="view===false">
            <div class="txt-label text-truncate d-none d-sm-block">
              {{ data.sw281s07 || '-----' }}
            </div>
            <div class="text-truncate d-xs-block d-sm-none">
              {{ data.sw281s07 || '-----' }}
            </div>
          </template>
          <template v-else>
            <p class="txt-label text-truncate d-none d-sm-block text-primary mb-0">
              {{ data.sw281s07|| '-----' }}
            </p>
            <p class="text-truncate d-xs-block d-sm-none text-primary mb-0">
              {{ data.sw281s07 || '-----' }}
            </p>
          </template>
        </b-form-group>
      </b-col>
      <b-col
        v-if="view===false"
        md="2"
      >
        <div
          class="d-none d-sm-block"
          style="margin-top: 1.95rem"
        />
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          @click.stop.prevent="deleteAction(data.sw281s01)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>{{ $t('Eliminar') }}</span>
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    view: {
      type: Boolean,
      default: false,
    },
    deleteAction: {
      type: Function,
      required: true,
    },
    updateAction: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    if ((props.data !== null) && (props.data !== undefined) && ('sw281s08' in props.data)) {
      const oProps = props
      oProps.data.sw281s08 = props.data.tipos.find(o => Number(o.id) === Number(props.data.sw281s08))
    }
  },
}
</script>

<style lang="scss" scoped>
.txt-label{
    line-height: $input-height;
}
</style>
