import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewData: state => state.listviewData.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },

  },
  actions: {

    async uploadSW281({ dispatch }, payload) {
      let resultUpload = null

      if ((payload !== null) && (payload !== undefined)) {
        if ('files' in payload) {
          if (payload.files.length > 0) {
            await payload.files.reduce(async (previous, file) => {
              const result = await previous
              return [...result, await dispatch('uploadSendSW281', file)]
            }, Promise.resolve([]))
              .then(result => {
                resultUpload = result
              })
          }
        }
      }

      const response = await new Promise((resolve, reject) => {
        if (resultUpload === null) {
          reject(new Error(i18n.t('Selecione um documento para carregar')))
        } else {
          resolve(resultUpload)
        }
      })

      return response
    },

    async uploadSendSW281({ rootGetters }, file) {
      return new Promise(resolve => {
        setTimeout(() => {
          const formData = new FormData()
          formData.append('keyReg', rootGetters['crm_leads_form/keyReg'])
          formData.append('keyDynamic', rootGetters['crm_leads_form/dynamicIDForm'])
          formData.append('name', file.name)
          formData.append('file', file)

          api.post(`${apiConfig.url_base_api}leads/uploadDocumentosConsentimento`, formData)
            .then(response => {
              try {
                if (typeof response.data === 'object') {
                  if (response.data.result === 'maxUploadExceeded') {
                    resolve({ message: response.data.message, error: true })
                  } else if (response.data.result !== 'OK') {
                    resolve({ message: i18n.t('Problema ao carregar o ficheiro {file}', { file: file.name }), error: true })
                  } else {
                    resolve({ message: i18n.t('Ficheiro {file} carregado com sucesso', { file: file.name }), error: false })
                  }
                } else {
                  resolve({ message: i18n.t('Problema ao carregar o ficheiro {file}', { file: file.name }), error: true })
                }
              } catch (error) {
                if ((error.response !== undefined) && (error.response.status !== undefined)) {
                  resolve({ message: i18n.t('Problema ao carregar o ficheiro {file}', { file: file.name }), error: true })
                }
              }
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                resolve({ message: i18n.t('Problema ao carregar o ficheiro {file}', { file: file.name }), error: true })
              }
            })
        }, 1000)
      })
    },

    async loadListviewData({
      commit, dispatch, rootGetters,
    }) {
      if (rootGetters['crm_leads_form/loaded'] === true) {
        await dispatch('request/cancelTokensPendingByUrl', 'leads/loadDocumentosConsentimento', { root: true })
      }

      const request = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('keyReg', rootGetters['crm_leads_form/keyReg'])
        formData.append('keyDynamic', rootGetters['crm_leads_form/dynamicIDForm'])
        formData.append('modeForm', rootGetters['crm_leads_form/modeFormAction'])

        api.post(`${apiConfig.url_base_api}leads/loadDocumentosConsentimento`, formData)
          .then(response => {
            try {
              if (response.data.json !== undefined) {
                commit('setListviewData', response.data.json)
                commit('setTotalRows', response.data.recordsTotal)

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os documentos do consentimento explícito')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os documentos do consentimento explícito')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os documentos do consentimento explícito')))
            }
          })
      })

      return request
    },

    async deleteSW281(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('regDel', payload)

        api.post(`${apiConfig.url_base_api}leads/deleteDocumentosConsentimento`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao eliminar o documento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar o documento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar o documento')))
            }
          })
      })
    },

    async updateSW281({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/updateInformDocConsentimento', { root: true })

      const request = await new Promise((resolve, reject) => {
        try {
          const formData = new FormData()
          formData.append('sw281s01', payload.sw281s01)
          formData.append('sw281s05', payload.sw281s05)
          formData.append('sw281s08', payload.sw281s08.id)

          api.post(`${apiConfig.url_base_api}leads/updateInformDocConsentimento`, formData)
            .then(response => {
              try {
                if (typeof response.data === 'object') {
                  if (response.data.error === 1) {
                    reject(new Error(response.data.msg))
                  } else {
                    resolve({ message: response.data.msg })
                  }
                } else {
                  reject(new Error(i18n.t('Problema ao atualizar a informação do documento')))
                }
              } catch (error) {
                if ((error.response !== undefined) && (error.response.status !== undefined)) {
                  reject(new Error(i18n.t('Problema ao atualizar a informação do documento')))
                }
              }
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao atualizar a informação do documento')))
              }
            })
        } catch (err) {
          reject(new Error(i18n.t('Problema ao atualizar a informação do documento')))
        }
      })

      return request
    },

  },
}
