<template>
  <div>

    <b-row
      v-if="((resumeErrorUpload.length > 0) && (formModeView===false)) || (createNew===true)"
    >
      <b-col md="12">
        <h4 class="pt-1 pb-1">
          {{ $t('Documentos consentimento explícito') }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="createNew===true"
    >
      <b-col
        md="12"
      >
        <b-button
          v-if="formModeView===false"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mb-1"
          :disabled="startUpload"
          @click="$refs.uploadDocsConsentimentoExp.$el.click()"
        >
          <template v-if="startUpload===true">
            <b-spinner
              small
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Aguarde...') }}</span>
          </template>
          <template v-else>
            <feather-icon
              icon="UploadIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Carregar documentos') }}</span>
          </template>
        </b-button>

        <b-form-file
          ref="uploadDocsConsentimentoExp"
          :hidden="true"
          plain
          multiple
          @input="uploadDocsSW281"
        />

      </b-col>
    </b-row>

    <b-row
      v-if="(resumeErrorUpload.length > 0) && (formModeView===false)"
    >
      <b-col
        md="12"
      >
        <b-alert
          v-for="(rowMessage, index) in resumeErrorUpload"
          :key="`message-error-upload-file-${index}`"
          v-height-fade
          show
          dismissible
          fade
          class="mb-1"
          :variant="((rowMessage.error === true) ? 'danger' : 'success')"
          @dismissed="removeResumeErrorUpload(index)"
        >
          <div class="alert-body">
            <span>{{ rowMessage.message }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
      >
        <item-sw281
          v-for="(rowSW281, index) in listviewData"
          :key="`item-sw281-${index}`"
          :data="rowSW281"
          :index="index"
          :view="(((formModeView===true) || (canDelete===false)) ? true : false)"
          :delete-action="removeSW281"
          :update-action="updateSW281"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BButton, BFormFile, BSpinner, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { showModalDeleteMessage } from '@core/utils/utils'
import { onUnmounted } from '@vue/composition-api'
import modelSW281 from '@store-modules/crm/leads/sw281'
import { heightFade } from '@core/directives/animations'
import ItemSw281 from '@/views/modules/crm/leads/form/form_data/sw281/item.vue'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormFile,
    BSpinner,
    BAlert,
    ItemSw281,
  },
  directives: {
    Ripple,
    heightFade,
  },
  mixins: [mixFormGeral],
  props: {
    createNew: {
      type: Boolean,
      required: false,
      default: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      startUpload: false,
      resumeErrorUpload: [],
    }
  },
  computed:
  {
    ...mapGetters('crm_leads_form', ['formModeView']),
    ...mapGetters('sw281', ['listviewData']),
  },
  methods: {
    async init() {
      try {
        await this.$store.dispatch('sw281/loadListviewData').catch(error => {
          this.showMsgErrorRequest(error)
        })
      } catch (err) {
        //
      }
    },
    async uploadDocsSW281(aFiles) {
      if (aFiles.length > 0) {
        try {
          this.startUpload = true
          await this.$store.dispatch('sw281/uploadSW281', { files: aFiles }).then(result => {
            this.resetFieldUpload()

            Object.keys(result).forEach(index => {
              if (result[index].error === true) {
                this.resumeErrorUpload.push(result[index])
              }
            })

            this.init()
          }).catch(error => {
            this.resetFieldUpload()
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
          this.resetFieldUpload()
        }
      }
    },
    resetFieldUpload() {
      this.startUpload = false
      this.resumeErrorUpload = []
      this.$refs.uploadDocsConsentimentoExp.reset()
    },
    async removeSW281(sw281s01) {
      const self = this

      try {
        if (sw281s01) {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar o documento?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              self.$root.$emit('app::loading', true)
              this.$store.dispatch('sw281/deleteSW281', sw281s01).then(res => {
                self.$root.$emit('app::loading', false)
                self.showMsgSuccessRequest(res)
                self.init()
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            }
          })
        }
      } catch (err) {
      //
      }
    },
    removeResumeErrorUpload(index) {
      this.resumeErrorUpload.splice(index, 1)
    },
    updateSW281(data) {
      const self = this

      this.$store.dispatch('sw281/updateSW281', data).catch(error => {
        self.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    const MODEL_SW281 = 'sw281'

    if (!store.hasModule(MODEL_SW281)) {
      store.registerModule(MODEL_SW281, modelSW281)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW281)) store.unregisterModule(MODEL_SW281)
      })
    }

    return {
    }
  },
}
</script>
